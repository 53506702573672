import { HttpClient } from '@angular/common/http';
import { clone } from '../../utils/clone';
import { map } from 'rxjs/operators';
import { PaginatedData } from '../../api/pagination/paginated-data';
import { isNullOrUndefinedOrBlank } from '../../utils/is-null-or-undefined';
import { MessageType } from '../../api/messaging/message-type';
import { HttpCacheService } from '../http-cache.service';
import { GlobalLoaderService } from '../global-loader.service';
import { Gender } from '../../api/gender';
import { TitleCasePipe } from '@angular/common';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { isNullOrUndefined } from 'util';
import { MessageCategory } from '../../api/messaging/message-category';
import { ApiQueryParams } from './api-query-params';
import { SystemType } from '../../api/system-config/system-type';
import { ServiceType } from '../../api/system-config/service-type';
import { AccreditationStatus } from '../../api/system-config/university';
import { ObtainedCredentialFlag } from '../../api/case/obtained-credential-flag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../http-cache.service";
import * as i3 from "../global-loader.service";
import * as i4 from "@angular/common";
var CaseService = /** @class */ (function () {
    function CaseService(httpClient, httpCacheService, globalLoader, titleCasePipe) {
        this.httpClient = httpClient;
        this.httpCacheService = httpCacheService;
        this.globalLoader = globalLoader;
        this.titleCasePipe = titleCasePipe;
    }
    Object.defineProperty(CaseService.prototype, "caseDefault", {
        get: function () {
            var genders = Gender;
            var value = {
                candidate: {
                    candidateSex: genders.Unspecified
                },
                email: {
                    creator: {
                        id: null,
                    },
                    messageSubject: null,
                    messageBody: null,
                    originalMessageBody: null,
                    creationTime: new Date().valueOf()
                },
                caseServices: [],
                sourceFiles: [],
                holdReason: this.holdReasonDefault,
                markupAssignee: null,
                rfedeadline: null
            };
            return clone(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CaseService.prototype, "holdReasonDefault", {
        get: function () {
            return clone({
                creator: {
                    id: null,
                },
                category: MessageCategory.HOLD,
                messageSubject: null,
                messageBody: null,
                originalMessageBody: null,
                messageType: MessageType.Note,
                creationTime: new Date().valueOf()
            });
        },
        enumerable: true,
        configurable: true
    });
    CaseService.prototype.createCase = function (newCase) {
        this.cleanCase(newCase);
        return this.httpClient.post('/api/case', newCase);
    };
    CaseService.prototype.saveDraftCase = function (newCase) {
        this.cleanCase(newCase);
        var caseToSend = clone(newCase);
        return this.httpClient.post('/api/case/draft', caseToSend);
    };
    CaseService.prototype.cancelDraftCase = function (caseId, note) {
        return this.httpClient.put("/api/case/draft/" + caseId + "/cancel", note);
    };
    CaseService.prototype.get = function (id, opts) {
        var url = "/api/case/" + id;
        if (opts && opts.nocache) {
            this.httpCacheService.ignoreOnce(url);
        }
        if (opts && opts.loaderIgnoreOnce) {
            this.globalLoader.ignoreOnce(url);
        }
        return this.httpClient.get(url);
    };
    CaseService.prototype.getAll = function () {
        return this.httpClient.get("/api/case");
    };
    CaseService.prototype.getAllPaginated = function (request) {
        return this.httpClient
            .post("/api/case/paginated", request.toObject())
            .pipe(
        // Update the given pagination object with values from the response.
        map(function (response) { return request.update(response); }));
    };
    CaseService.prototype.getAllServicesPaginated = function (request) {
        return this.httpClient
            .post("/api/case/service/paginated", request.toObject())
            .pipe(
        // Update the given pagination object with values from the response.
        map(function (response) { return request.update(response); }));
    };
    CaseService.prototype.count = function (filter) {
        var data = new PaginatedData();
        data.query = filter;
        return this.httpClient.post("/api/case/counts", data.toObject());
    };
    CaseService.prototype.countServices = function (filter) {
        var data = new PaginatedData();
        data.query = filter;
        // data.addColumnValue('id');
        return this.httpClient.post("/api/case/service/counts", data.toObject());
    };
    CaseService.prototype.updateCaseServices = function (caseId, services) {
        return this.httpClient
            .put("/api/case/" + caseId + "/services", services);
    };
    CaseService.prototype.updateAdditionalServices = function (caseServiceId, additionalServices) {
        return this.httpClient.put("/api/case/service/" + caseServiceId + "/additional-services", additionalServices);
    };
    CaseService.prototype.addCaseService = function (caseId, service) {
        return this.httpClient
            .post("/api/case/" + caseId + "/service", service);
    };
    CaseService.prototype.removeCaseService = function (caseId, serviceId) {
        return this.httpClient
            .delete("/api/case/" + caseId + "/service/" + serviceId);
    };
    CaseService.prototype.removeCaseServices = function (caseId, theCaseServiceIds) {
        return this.httpClient
            .request('delete', "/api/case/" + caseId + "/services", { body: theCaseServiceIds });
    };
    CaseService.prototype.addCompletedTranslations = function (caseId, fileIds) {
        return this.httpClient
            .post("/api/case/" + caseId + "/completed-translation-files", fileIds);
    };
    CaseService.prototype.addSourceFiles = function (caseId, fileIds) {
        return this.httpClient
            .post("/api/case/" + caseId + "/source-files", fileIds);
    };
    CaseService.prototype.updateCaseCandidate = function (caseId, candidate) {
        return this.httpClient.put("/api/case/" + caseId + "/candidate", candidate);
    };
    CaseService.prototype.updateCaseDeadline = function (caseId, deadline) {
        return this.httpClient.put("/api/case/" + caseId + "/deadline", deadline);
    };
    CaseService.prototype.updateRfeDeadline = function (caseId, deadline) {
        return this.httpClient.put("/api/case/" + caseId + "/rfe-deadline", deadline);
    };
    CaseService.prototype.updateCaseTurnaround = function (caseId, turnaround) {
        return this.httpClient.put("/api/case/" + caseId + "/turnaround-type", turnaround);
    };
    CaseService.prototype.updateCaseIntakeDate = function (caseId, intakeDate) {
        return this.httpClient.put("/api/case/" + caseId + "/startdate", intakeDate);
    };
    CaseService.prototype.updateCaseDeliveryStatus = function (caseId, deliveryPreferences) {
        return this.httpClient.put("/api/case/" + caseId + "/delivery-preferences", deliveryPreferences);
    };
    CaseService.prototype.updateCaseReference = function (caseId, type, value) {
        return this.httpClient.put("/api/case/" + caseId + "/reference-number/" + type, value);
    };
    CaseService.prototype.updateCaseCustomerInfo = function (caseId, customerInfo) {
        return this.httpClient.put("/api/case/" + caseId + "/customer-info", customerInfo);
    };
    CaseService.prototype.getEvaluationFiles = function (caseId, caseServiceId) {
        return this.httpClient
            .get("/api/case/" + caseId + "/service/" + caseServiceId + "/evaluation-files");
    };
    CaseService.prototype.toggleTranslationComplete = function (caseId, complete, confirmed) {
        return this.httpClient
            .put("/api/case/" + caseId + "/toggle-complete-translation/" + complete + "/confirmed/" + confirmed, null);
    };
    CaseService.prototype.generateServiceDeliveryFile = function (caseServiceId, fileKey) {
        return this.httpClient.post("/api/case/service/" + caseServiceId + "/delivery-file/" + fileKey, null);
    };
    CaseService.prototype.replaceDeliveryFile = function (caseServiceId, request) {
        return this.httpClient
            .post("/api/case/service/" + caseServiceId + "/replace-delivery-files", request);
    };
    CaseService.prototype.toggleExpertSourceFileAccess = function (caseId, sourceFileId, access) {
        return this.httpClient
            .put("/api/case/" + caseId + "/source/file/" + sourceFileId + "/expert-access/" + access, null);
    };
    CaseService.prototype.toggleTranslatedSourceFileAccess = function (caseId, translatedFileId, makeSource) {
        return this.httpClient
            .put("/api/case/" + caseId + "/translation/file/" + translatedFileId + "/source/" + makeSource, null);
    };
    CaseService.prototype.toggleTranslatedDeliveryFileAccess = function (caseId, translatedFileId, makeDelivery) {
        return this.httpClient
            .put("/api/case/" + caseId + "/translation/file/" + translatedFileId + "/delivery/" + makeDelivery, null);
    };
    CaseService.prototype.updateCaseBillingContact = function (caseId, caseBillingContact) {
        return this.httpClient
            .put("/api/case/" + caseId + "/billing/contact", caseBillingContact);
    };
    CaseService.prototype.createBatch = function (batch) {
        return this.httpClient
            .post("/api/case/batch", batch);
    };
    CaseService.prototype.buildBatchIntakeData = function (spreadSheetFsKey, sourceFsKey, data) {
        var url = "/api/case/bulk/build-bulk-case-intake-data/" + spreadSheetFsKey + "/source-files/" + sourceFsKey;
        return this.httpClient
            .post(url, data);
    };
    CaseService.prototype.getBatchCommonData = function (batchNumber) {
        return this.httpClient.get("api/case/batch/" + batchNumber);
    };
    /**
     * Generates the field pairing list for the bulk intake form
     * @param fileKey Filekey property of the excel spreadsheet
     */
    CaseService.prototype.parseBulkData = function (fileKey) {
        return this.httpClient
            .get("/api/case/bulk/parse-header/" + fileKey);
    };
    CaseService.prototype.removeCaseFile = function (caseId, fileId, fileType) {
        var url = "/api/case/" + caseId + "/remove/file/" + fileId;
        var queryOptions = {
            queryParams: { fileType: fileType }
        };
        url += new ApiQueryParams(queryOptions).generateQueryParams();
        return this.httpClient.get(url);
    };
    CaseService.prototype.addTranslationFile = function (caseId, fileId) {
        return this.httpClient.post("/api/case/" + caseId + "/file/" + fileId + "/translation-file", null);
    };
    CaseService.prototype.cleanCase = function (newCase) {
        // Remove email property if body is empty.
        if (newCase.email && isNullOrUndefinedOrBlank(newCase.email.messageBody)) {
            delete newCase.email;
        }
        var toTrimProperties = [
            'clientReference1',
            'clientReference2',
            'employeeId',
            'email.messageBody',
            'candidate.candidateName',
            'candidate.positionTitle',
            'candidate.employerEndClient',
            'candidate.degreesRequired',
            'caseContact.firstName',
            'caseContact.lastName',
            'caseContact.email',
            'caseContact.phoneNumber.phoneNumber'
        ];
        // Trim the whitespaces of text fields
        for (var _i = 0, toTrimProperties_1 = toTrimProperties; _i < toTrimProperties_1.length; _i++) {
            var propertyPath = toTrimProperties_1[_i];
            var propertyValue = _get(newCase, propertyPath);
            if (isNullOrUndefined(propertyValue) || typeof propertyValue !== 'string') {
                continue;
            }
            propertyValue = propertyValue.trim();
            // Set the trimmed value
            _set(newCase, propertyPath, propertyValue);
        }
        if (newCase.holdReason && isNullOrUndefinedOrBlank(newCase.holdReason.messageBody)) {
            newCase.holdReason = null;
        }
        // Fix capitalization for Candidate Name
        if (newCase.candidate) {
            newCase.candidate.candidateName = this.autoCapitalizeName(newCase.candidate.candidateName);
        }
        if (newCase.caseServices) {
            if (!newCase.caseServices.some(function (service) { return service.service.serviceType === ServiceType.AcademicEvaluation; })) {
                newCase.caseProjectObtainedCredential = null;
            }
        }
        if (newCase.caseProjectObtainedCredential && !newCase.caseProjectObtainedCredential.automatedAcademicEvaluation) {
            newCase.caseProjectObtainedCredential = { automatedAcademicEvaluation: false };
        }
    };
    CaseService.prototype.getQualityIssues = function (caseServiceId, serviceStage) {
        return this.httpClient
            .get("/api/case/service/" + caseServiceId + "/quality-issues/" + serviceStage);
    };
    CaseService.prototype.downloadAsDeliverables = function (theBatchId, batchDeliverableRequest) {
        var url = "/api/case/bulk/deliverable/download/";
        var queryOptions = {
            queryParams: { caseProjectIds: batchDeliverableRequest.caseProjectIds }
        };
        this.httpClient.post(url, batchDeliverableRequest)
            .toPromise()
            .then(function (resp) {
            var downloadUrl = 'api/public/case/bulk/' + theBatchId + '/deliverable/zip/' + resp.response;
            downloadUrl += new ApiQueryParams(queryOptions).generateQueryParams();
            window.open(downloadUrl, '_self');
        });
    };
    CaseService.prototype.getCaseServicePdfProcessStatus = function (caseServiceId) {
        return this.httpClient
            .get("/api/case/service/" + caseServiceId + "/pdf-status");
    };
    CaseService.prototype.updateSalesPerson = function (caseId, salesPersonId) {
        if (salesPersonId) {
            return this.httpClient
                .put("/api/case/" + caseId + "/sales-person/" + salesPersonId, null);
        }
        else {
            return this.httpClient
                .put("/api/case/" + caseId + "/sales-person", null);
        }
    };
    CaseService.prototype.updateAccountManager = function (caseId, accountManagerId) {
        if (accountManagerId) {
            return this.httpClient.put("/api/case/" + caseId + "/account-manager/" + accountManagerId, null);
        }
        else {
            return this.httpClient.put("/api/case/" + caseId + "/sales-person", null);
        }
    };
    CaseService.prototype.getSalesforceOpportunity = function (caseId) {
        return this.httpClient.get("/api/salesforce/" + SystemType.ParkEval + "/" + caseId);
    };
    CaseService.prototype.syncSalesforceOpportunity = function (caseId) {
        return this.httpClient.post("/api/salesforce/" + SystemType.ParkEval + "/" + caseId, null);
    };
    CaseService.prototype.regenerateDeliveryFilename = function (serviceIds) {
        var url = "/api/case/regenerate/delivery/filename";
        return this.httpClient.put(url, serviceIds);
    };
    CaseService.prototype.getTranslationActivities = function (caseId) {
        var url = "/api/case/" + caseId + "/case-translation-activities";
        return this.httpClient.get(url);
    };
    CaseService.prototype.autoCapitalizeName = function (name) {
        if (isNullOrUndefinedOrBlank(name) || typeof name !== 'string') {
            return '';
        }
        return this.titleCasePipe.transform(name.trim());
    };
    CaseService.prototype.updateCaseHoldMessage = function (caseId, note) {
        return this.httpClient.put("/api/case/hold-note/" + caseId, note);
    };
    CaseService.prototype.getOtherServicesOfMediaContact = function (adsCaseServiceId) {
        var url = "/api/park-ads/case-service/" + adsCaseServiceId + "/other-services-of-media-contact";
        return this.httpClient.get(url);
    };
    CaseService.prototype.scanAcademicEquivalence = function (caseData) {
        // Always clear scannedObtainedCredential before creating a request or else backend will return the same values
        caseData.caseProjectObtainedCredential.scannedObtainedCredential = {};
        return this.httpClient
            .post("/api/case/intake/scan-academic-equivalence", caseData);
    };
    CaseService.prototype.isAcaNextStageProofreading = function (obtainedCredential) {
        try {
            var isSchoolAccredited = obtainedCredential.school.accreditationStatus === AccreditationStatus.Accredited;
            var isNoFlag = obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.None;
            return isSchoolAccredited && isNoFlag;
        }
        catch (e) {
            // If no school or scannedObtainCredential, this will error out.
            // Instead of adding additional null checks for those values,
            // we will just catch null errors here and return false if one of those values we need is null
            return false;
        }
    };
    CaseService.prototype.isSchoolNotAccredited = function (obtainedCredential) {
        var schoolAccreditation = obtainedCredential.school.accreditationStatus;
        return schoolAccreditation === AccreditationStatus.Unknown ||
            schoolAccreditation === AccreditationStatus.NotAccredited;
    };
    CaseService.prototype.isAcaFlaggedMarkupOnly = function (obtainedCredential) {
        return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.MarkupOnly;
    };
    CaseService.prototype.isAcaFlaggedMarkupThenWriting = function (obtainedCredential) {
        return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.MarkupAndWriting;
    };
    CaseService.prototype.isAcaSkipMarkupAndWriting = function (obtainedCredential) {
        return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.None;
    };
    CaseService.prototype.isAcaWritingOnly = function (obtainedCredential) {
        try {
            return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.WritingOnly;
        }
        catch (e) {
            return false;
        }
    };
    CaseService.prototype.getSuggestedExpertDeadline = function (caseId) {
        return this.httpClient
            .get("/api/case/" + caseId + "/suggested-expert-deadline");
    };
    CaseService.prototype.getDefaultDeadlineTime = function () {
        return this.httpClient.get("/api/case/default-deadline-time");
    };
    CaseService.prototype.createPreviewEmail = function (newPreviewEmail) {
        return this.httpClient
            .post('/api/park-ads/vendor-quote-request/preview-email', newPreviewEmail);
    };
    CaseService.prototype.getWizrOutputResponse = function (caseServiceId) {
        return this.httpClient
            .get("/api/case/service/" + caseServiceId + "/wizr");
    };
    CaseService.ngInjectableDef = i0.defineInjectable({ factory: function CaseService_Factory() { return new CaseService(i0.inject(i1.HttpClient), i0.inject(i2.HttpCacheService), i0.inject(i3.GlobalLoaderService), i0.inject(i4.TitleCasePipe)); }, token: CaseService, providedIn: "root" });
    return CaseService;
}());
export { CaseService };
